import { string } from 'yup'
import { VALIDATE_PHONE_REGEX } from '@mindfulchefuk/constants'

export const validatePhoneNumber = string()
  .required('Phone number is required')
  .min(8, 'Phone number must be 8 or more characters')
  .max(15, 'Phone number must be 15 characters or less')
  .matches(
    VALIDATE_PHONE_REGEX,
    'Phone number must use numbers 0 - 9 and start with a 0'
  )
