import { dataAttr } from '@mindfulchefuk/utils/dataAttr'
import { useFormControlContext } from '@mindfulchefuk/forms/hooks/useFormControlContext'

interface DataProps {
  'data-disabled'?: string
  'data-invalid'?: string
  'data-checked'?: string
}

export const useFormControlLabel = (as?: string): [string, DataProps] => {
  const [control, field] = useFormControlContext()

  const htmlFor = as === 'label' ? control.id : undefined
  const dataProps = {
    'data-disabled': dataAttr(!!control.disabled),
    'data-invalid': dataAttr(!!control.error),
    'data-checked': dataAttr(!!field.checked),
  }

  return [htmlFor, dataProps]
}
