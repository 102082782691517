import React, { FunctionComponent } from 'react'
import { Checkbox } from '@mindfulchefuk/design-system/Forms/Checkbox/Checkbox'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import { InputProps } from '@mindfulchefuk/design-system/Forms/types'

export const FormCheckbox: FunctionComponent<InputProps> = ({
  value,
  ...htmlProps
}) => {
  const field = useFormControlInput<string | number | string[]>()

  const checked = Array.isArray(field.value)
    ? field.value.includes(value as string)
    : field.checked

  return <Checkbox {...htmlProps} {...field} value={value} checked={checked} />
}
