import { Schema } from 'yup'

export const useYupFieldValidation = (
  schema?: Schema<unknown>
): ((value: string) => Promise<string>) => {
  let message: string | undefined

  if (!schema) return undefined

  return async (value) => {
    try {
      await schema.validate(value)
    } catch (err) {
      message = err.message
    }

    return message
  }
}
