import * as Yup from 'yup'

export const validateNewPassword = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must have 6 or more characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(6, 'Password must have 6 or more characters')
    .oneOf(
      [Yup.ref('password'), null],
      'The passwords you entered do not match.'
    )
    .required('Confirm password is required'),
})
