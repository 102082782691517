import {
  RadioGroup,
  RadioGroupHTMLProps,
} from '@mindfulchefuk/design-system/Forms/RadioButton/RadioGroup'
import React, { FunctionComponent } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks/useFormControlInput'

export const FormRadioGroup: FunctionComponent<RadioGroupHTMLProps> = (
  htmlProps
) => {
  const inputProps = useFormControlInput<string | number | readonly string[]>()

  return <RadioGroup {...inputProps} {...htmlProps} />
}
