import { string } from 'yup'

export const validateStreetLine1 = string()
  .required('Address line 1 is required')
  .min(3, 'Address line 1 must be at least 3 characters')
  .max(50, 'Address line 1 must be 50 characters or less')

export const validateStreetLine2 = string().max(
  50,
  'Address line 2 must be 50 characters or less'
)

export const validateTown = string()
  .required('Town is required')
  .min(3, 'Town must be at least 3 characters')
  .max(50, 'Town must be 50 characters or less')
