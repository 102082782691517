import * as Formik from 'formik'
import { AriaAttributes } from 'react'
import { useFormControlContext } from '@mindfulchefuk/forms/hooks/useFormControlContext'

type FormControlFieldProps<Val = unknown> = Formik.FieldInputProps<Val> &
  AriaAttributes & { id: string; disabled?: boolean; type?: string }

export const useFormControlInput = <
  Val = unknown
>(): FormControlFieldProps<Val> => {
  const [control, field] = useFormControlContext<Val>()

  return {
    ...field,
    'aria-invalid': !!control.error,
    'aria-describedby': control.error ? `${control.id}-feedback` : undefined,
    type: control.type,
    id: control.id,
    disabled: control.disabled,
  }
}
