import { useFormControlContext } from '@mindfulchefuk/forms/hooks/useFormControlContext'

interface FormControlErrorProps {
  'aria-live': 'polite'
  id: string
}

export const useFormControlError = (): [string, FormControlErrorProps] => {
  const [control] = useFormControlContext()

  return [
    control.error,
    { 'aria-live': 'polite', id: `${control.id}-feedback` },
  ]
}
