import { dataAttr } from '@mindfulchefuk/utils/dataAttr'
import React, { useContext } from 'react'

export interface RadioGroupContextValue {
  name: string
  value: string | number | readonly string[]
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

type UseRadioResult = [{ 'data-checked'?: string }, RadioGroupContextValue]

export const RadioGroupContext =
  React.createContext<RadioGroupContextValue>(null)

const useRadioGroupContext = (): RadioGroupContextValue => {
  const value = useContext(RadioGroupContext)

  if (!value) {
    throw new Error('Must be inside RadioGroupProvider')
  }

  return value
}

export const useRadio = (
  value: string | number | readonly string[]
): UseRadioResult => {
  const { value: fieldValue, name, onChange, onBlur } = useRadioGroupContext()

  const checked = value === fieldValue

  const labelProps = { 'data-checked': dataAttr(checked) }
  const inputProps = { checked, name, onChange, value, onBlur }

  return [labelProps, inputProps]
}
