import { useField } from 'formik'
import React, { FunctionComponent, InputHTMLAttributes } from 'react'
import { Schema } from 'yup'
import { FormErrorMessage } from '@mindfulchefuk/forms/FormErrorMessage'
import { FormControlReset } from '@mindfulchefuk/design-system/Forms/form.styles'
import {
  FormControlProvider,
  useYupFieldValidation,
} from '@mindfulchefuk/forms/hooks'
import { callAllHandlers } from './utils/callAllHandlers'

interface FormControlProps {
  id?: string
  name?: string
  as?: string
  validationSchema?: Schema<unknown>
  isDisabled?: boolean
  hasCustomError?: boolean
  type?: InputHTMLAttributes<HTMLInputElement>['type']
  onChange?: React.ChangeEventHandler
  onBlur?: React.FocusEventHandler
}

export const FormControl: FunctionComponent<FormControlProps> = ({
  children,
  as = 'div',
  id,
  name,
  validationSchema,
  hasCustomError,
  isDisabled,
  type,
  onChange,
  onBlur,
}) => {
  const validate = useYupFieldValidation(validationSchema)
  const [field, meta, helpers] = useField({ name, validate, type })
  field.onChange = callAllHandlers(onChange, field.onChange)
  field.onBlur = callAllHandlers(onBlur, field.onBlur)

  const error = meta.touched ? meta.error : undefined

  const control = {
    id,
    error,
    isDisabled,
    as,
    type,
  }

  return (
    <FormControlProvider value={[control, field, meta, helpers]}>
      <FormControlReset as={as}>
        {children}
        {!hasCustomError && <FormErrorMessage />}
      </FormControlReset>
    </FormControlProvider>
  )
}
