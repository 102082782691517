import React, { VoidFunctionComponent } from 'react'
import { ErrorMessage } from '@mindfulchefuk/design-system'
import { useFormControlError } from '@mindfulchefuk/forms/hooks'

export const FormErrorMessage: VoidFunctionComponent = () => {
  const [error, props] = useFormControlError()

  if (!error) return null

  return <ErrorMessage {...props}>{error}</ErrorMessage>
}
