import React, { FunctionComponent } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import { RadioBox } from '@mindfulchefuk/design-system'
import { useFormikContext } from 'formik'

interface Props {
  value: string | number
  'data-testid'?: string
}
export const FormRadioBox: FunctionComponent<Props> = ({
  value,
  'data-testid': testId,
  children,
}) => {
  const { setFieldValue } = useFormikContext()
  const field = useFormControlInput<string>()
  const checked = field.value === value

  const toggleChecked = () => {
    if (checked) {
      setFieldValue(field.name, '')
    }
  }

  return (
    <RadioBox
      {...field}
      value={value}
      data-testid={testId}
      checked={checked}
      onClick={toggleChecked}
    >
      {children}
    </RadioBox>
  )
}
