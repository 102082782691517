import React, { FunctionComponent } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import { RadioButton } from '@mindfulchefuk/design-system'

interface Props {
  value: string | number
  'data-testid'?: string
}
export const FormRadioButton: FunctionComponent<Props> = ({
  value,
  'data-testid': testId,
  children,
}) => {
  const field = useFormControlInput<string>()
  const checked = field.value === value

  return (
    <RadioButton
      {...field}
      value={value}
      data-testid={testId}
      checked={checked}
    >
      {children}
    </RadioButton>
  )
}
