import React, { FunctionComponent, HTMLAttributes, useMemo } from 'react'
import { RadioGroupContext, RadioGroupContextValue } from './useRadio'

export type RadioGroupHTMLProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
>

type RadioGroupProps = RadioGroupContextValue & RadioGroupHTMLProps

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  children,
  name,
  value,
  onChange,
  onBlur,
  ...htmlProps
}) => {
  const contextValue = useMemo(
    () => ({
      name,
      value,
      onChange,
      onBlur,
    }),
    [name, value, onChange, onBlur]
  )
  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div role="group" {...htmlProps}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}
