import * as Formik from 'formik'
import React, { useContext } from 'react'

interface FormControl {
  id?: string
  as?: string
  error?: string
  type?: string
  disabled?: boolean
}

type FormControlContext<Val = unknown> = [
  FormControl,
  Formik.FieldInputProps<Val>,
  Formik.FieldMetaProps<Val>,
  Formik.FieldHelperProps<Val>
]

const FormControlContext = React.createContext(null as FormControlContext)

export const { Provider: FormControlProvider } = FormControlContext

export const useFormControlContext = <
  Val = unknown
>(): FormControlContext<Val> => {
  const value = useContext(FormControlContext) as FormControlContext<Val>

  if (!value) {
    throw new Error('Must be inside FormControlContext.Provider')
  }

  return value
}
