import React, { forwardRef, InputHTMLAttributes, ReactElement } from 'react'

import { InputWithIcon } from '@mindfulchefuk/design-system/Forms/InputWithIcon'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import { Icon } from '@mindfulchefuk/design-system'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  disableErrorIcon?: boolean
  customIcon?: ReactElement
}

export const FormInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const fieldProps = useFormControlInput<string | number>()

  const { customIcon, disableErrorIcon, ...rest } = props

  return (
    <InputWithIcon {...fieldProps} {...rest} ref={ref}>
      {fieldProps['aria-invalid'] && !disableErrorIcon ? (
        <Icon color="tomato" type="warning" size={20} />
      ) : (
        customIcon && customIcon
      )}
    </InputWithIcon>
  )
})
